// import ee from 'experience-engine'
import EventEmitter2 from 'eventemitter2'
import debug from 'src/utils/debug'
import uuid from 'src/utils/uuid'
import { get, clone } from 'lodash'
// write regex that finds imports that does not end with .vue

const ee = {
  util: {
    get,
    clone,
    uuid
  }
}

export default ({ app, store }) => {
  app.config.globalProperties.$ee = ee

  const debugEvents = debug.extend('events')
  app.config.globalProperties.$debug = debug
  const event = new EventEmitter2({ wildcard: true, maxListeners: 20, verboseMemoryLeak: true })
  app.config.globalProperties.$bus = event

  event.onAny((name, payload) => {
    // Uncomment next line to back-trace where events came from...
    // console.trace()
    debugEvents(name, payload)
  })

  app.mixin({
    // Attaches events...
    created () {
      // Adds an automatically named local "debug"
      this.debug = this.$debug.extend(this.$options.name || '[unnamed]')
    },
    mounted () {
      if (this.$options.events) {
        const that = this
        Object.keys(this.$options.events).forEach(event => {
          this.$bus.on(event, this.$options.events[event].bind(that))
        })
      }
    },
    // Detaches events...
    beforeUnmount () {
      if (this.$options.events) {
        Object.keys(this.$options.events).forEach(event => {
          this.$bus.off(event, this.$options.events[event])
        })
      }
    }
  })
}

export { ee }
