<template>
  <div>
    <div
      v-if="mini"
      class="row justify-between no-wrap items-center"
    >
      <div class="row no-wrap q-gutter-x-md items-center">
        <div class="text-bold">
          {{ miniTitle }}
        </div>
        <div
          class="rounded-borders"
          style="width:fit-content;"
          :style="borderColor('grey-7')"
        >
          <div class="text-center text-grey-7 q-px-sm text-bold">
            <span v-if="recurringType !== 'uncapped'">{{ localImpressionsPerPeriod }} / </span>{{ recurringType }}
          </div>
        </div>
      </div>
      <div>
        <slot name="tempSchedule" />
      </div>
    </div>
    <div v-else>
      <q-card
        flat
        bordered
        class="q-pa-md q-card__small-radius"
      >
        <div class="text-bold q-mb-md">
          {{ $t('create.template.impressionLabel') }}
        </div>
        <div class="column">
          <div
            v-for="(item, index) in recurringOptions"
            :key="index"
          >
            <q-radio
              class="q-py-sm"
              :model-value="recurringType"
              v-bind="item"
              :disable="readonlyFields"
              @update:model-value="$emit('update:recurringType', $event)"
            />
            <q-separator v-if="(index + 1) !== recurringOptions.length" />
          </div>
        </div>
      </q-card>
      <div class="row q-pt-md q-pb-md">
        <div class="col-sm-6 q-pr-sm">
          <label v-if="recurringType !== 'uncapped'">
            {{ recurringType === 'day' ? $t('create.fields.totalImpressionDay') : $t('create.fields.totalImpressionWeek') }}
          </label>
          <q-input
            v-if="recurringType !== 'uncapped'"
            outlined
            :model-value="localImpressionsPerPeriod"
            :readonly="readonlyFields"
            mask="###,###,###"
            unmasked-value
            reverse-fill-mask
            maxlength="11"
            :rules="[val => $rules.required(val), val => !isNaN(parseInt(val)) || '*Required', val => val != 0 || '*Required']"
            @update:model-value="convertValueToNumber"
          />
        </div>
      </div>
      <div
        v-if="tempSchedule"
        class="text-grey-7"
      >
        {{ $t('components.campaignTempScheduleDialog.warningBanner', { startDate: displayStartDate, endDate: displayEndDate }) }}
      </div>
      <!-- start date -->
      <div class="row q-pt-md">
        <div class="col-sm-6 q-pr-sm">
          <label>{{ $t('pages.editCampaign.startDate') }}</label>
          <q-input
            ref="dateStartRef"
            outlined
            :model-value="startDate"
            :readonly="readonlyFields"
            type="date"
            :filled="Dark.isActive"
            :bg-color="Dark.isActive ? 'grey-6' : 'white'"
            input-class="text-black"
            reactive-rules
            :rules="[val => dateStart !== undefined || 'Start date is required',
                     val => !!val|| 'Start date is required',
                     val => tempSchedule ? (isoIsWithinRange(val, dateStart, dateEnd, currentTimeZone) || 'Out of range') : true,
                     val => isoIsBefore(val, tempSchedule ? tempDateEnd : dateEnd, currentTimeZone) || $t('pages.campaign.dateStartWarning'),
            ]"
            @update:model-value="setDateLocale($event, 'dateStart')"
          />
        </div>
        <!-- end date -->
        <div class="col-6">
          <label>
            {{ $t('pages.editCampaign.endDate') }}
          </label>
          <q-input
            ref="dateEndRef"
            outlined
            :model-value="endDate"
            reactive-rules
            type="date"
            :filled="Dark.isActive"
            :bg-color="Dark.isActive ? 'grey-6' : 'white'"
            input-class="text-black"
            :rules="[val => dateEnd !== undefined || 'End date is required',
                     val => !!val || 'End date is required',
                     val => tempSchedule ? (isoIsWithinRange(val, dateStart, dateEnd, currentTimeZone) || 'Out of range') : true,
                     val => isoIsAfter(val, tempSchedule ? tempDateStart : dateStart, currentTimeZone) || $t('pages.campaign.dateEndWarning'),
            ]"
            @update:model-value="setDateLocale($event, 'dateEnd')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue'
import { i18n } from 'src/boot/i18n'
import { isoIsBefore, isoIsBeforeOrEqual, isoIsAfter, isoIsWithinRange } from 'src/utils/date'
import { usePresetDateTime } from 'src/composables/use-preset-datetime'
import { Dark } from 'quasar'
import { borderColor } from 'src/utils/color'
export default {
  name: 'CampaignSchedule',
  props: {
    mini: { type: Boolean, default: false },
    miniTitle: String,
    dateStart: String,
    dateEnd: String,
    tempDateStart: String,
    tempDateEnd: String,
    impressionsPerPeriod: { type: [Number, String], default: 10000 },
    recurringType: { type: String, default: 'day' },
    tempSchedule: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false }
  },
  emits: ['update:impressionsPerPeriod', 'update:recurringType'],
  setup (props, { emit }) {
    const { $store } = getCurrentInstance().proxy
    const dateEndRef = ref(null)
    const dateStartRef = ref(null)
    const localImpressionsPerPeriod = computed(() => props.impressionsPerPeriod.toLocaleString('en-US'))
    const { presetDateTime } = usePresetDateTime()
    const currentTimeZone = computed(() => { return { zone: $store.getters['adminapp/getTimeZone'] } })

    const currentDate = computed(() => presetDateTime(new Date().toISOString()).toISODate())
    const readonlyFields = computed(() => props.readonly && !isoIsBeforeOrEqual(currentDate.value, props.dateStart, currentTimeZone.value))
    const recurringOptions = ref([
      { label: i18n.t('create.fields.impressionDaily'), val: 'day' },
      { label: i18n.t('create.fields.impressionWeekly'), val: 'week' },
      { label: i18n.t('create.fields.impressionUncapped'), val: 'uncapped' }
    ])
    const startDate = computed(() => {
      if (!props.tempSchedule && props.dateStart === '') return currentDate.value
      return presetDateTime(props.tempSchedule ? props.tempDateStart : props.dateStart).toISODate()
    })
    const endDate = computed(() => {
      if (!props.tempSchedule && props.dateEnd === '') return currentDate.value
      return presetDateTime(props.tempSchedule ? props.tempDateEnd : props.dateEnd).toISODate()
    })

    const displayStartDate = computed(() => presetDateTime(props.dateStart).toLocaleString())
    const displayEndDate = computed(() => presetDateTime(props.dateEnd).toLocaleString())

    const convertValueToNumber = (evt) => {
      emit('update:impressionsPerPeriod', parseFloat(evt.replaceAll(',', '')))
    }

    const setDateLocale = (date, name) => {
      emit(`update:${name}`, presetDateTime(date).toISO())
    }

    const validate = async () => {
      const dateEnd = await dateEndRef.value?.validate()
      const dateStart = await dateStartRef.value?.validate()
      return [dateEnd, dateStart]
    }

    return {
      Dark,
      // refs
      dateEndRef,
      dateStartRef,
      // vars
      recurringOptions,
      localImpressionsPerPeriod,
      startDate,
      endDate,
      readonlyFields,
      displayStartDate,
      displayEndDate,
      currentTimeZone,
      // methods
      isoIsBefore,
      isoIsAfter,
      isoIsWithinRange,
      convertValueToNumber,
      validate,
      setDateLocale,
      borderColor
    }
  }
}
</script>
