import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getAuth } from 'firebase/auth'

let _app, _rtdb, _auth

if (!import.meta.env.VITE_FIREBASE_API) {
  console.error('[ERROR] Firebase api key is missing. Live feature will not work.')
} else {
  const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API,
    authDomain: import.meta.env.VITE_FIREBASE_DOMAIN,
    databaseURL: import.meta.env.VITE_FIREBASE_DATABASE,
    projectId: import.meta.env.VITE_FIREBASE_PROJECTID,
    storageBucket: import.meta.env.VITE_FIREBASE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGINGSENDERID,
    appId: import.meta.env.VITE_FIREBASE_APPID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENTID
  }

  _app = initializeApp(firebaseConfig)
  _rtdb = getDatabase(_app)
  _auth = getAuth(_app)
}

export const app = _app
export const rtdb = _rtdb
export const auth = _auth
