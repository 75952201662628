import cloneDeep from 'lodash/cloneDeep'
import unset from 'lodash/unset'
import set from 'lodash/set'

export function toggleSelect (list, item, itemKey, adding) {
  if (typeof adding === 'undefined') {
    adding = !list.some(i => i[itemKey] === item[itemKey])
  }
  if (adding) {
    return list.concat([item])
  } else {
    return list.filter(i => i[itemKey] !== item[itemKey])
  }
}
/**
 * copies original object and updates properties.
 * @param {*} obj
 * @param {*} path
 * @param {*} value
 * @param {*} unsetOnEmpty
 * @returns new object
 */
export function cloneAndSetProperty (obj, path, value, unsetOnEmpty) {
  const newContent = cloneDeep(obj)
  if (unsetOnEmpty && (value === null || typeof value === 'undefined' || value === '')) {
    unset(newContent, path)
  } else {
    set(newContent, path, value)
  }
  return newContent
}

export function createUploadPayload (resource) {
  const data = {}
  const payload = Object.keys(resource).reduce((_payload, key) => {
    // BUG
    // seems to be a bug, this does not push anything other than a object. It accepts array with
    // a file, but if there is no file it will not pass the data.
    const value = resource[key]
    if (value instanceof File) {
      _payload.append(`files.${key}`, value, value.name)
    } else if (Array.isArray(value) && key !== 'data') {
      value.filter(i => i instanceof File).forEach(i => {
        _payload.append(`files.${key}`, i, i.name)
      })
    } else {
      data[key] = value
    }
    return _payload
  }, new FormData())
  payload.append('data', JSON.stringify(data))

  return payload
}
