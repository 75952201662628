<template>
  <div
    class="column no-wrap"
    :class="{ 'q-gutter-y-md': !dense }"
  >
    <!-- drag and drop -->
    <div
      class="col-7 column no-wrap"
      :class="{ 'q-gutter-y-sm': !dense }"
    >
      <div
        class="row items-center justify-center dropZone"
        :class="{
          'col-5': multiple && storedFile,
          'col': !multiple,
          'dropZone__disabled cursor-not-allowed': storedUrl || isLoading,
          'dropZone__outlined': outlined,
          'dropZone__filled': filled
        }"
        @drop="dropHandler"
        @dragover.prevent
        @dragenter.prevent
      >
        <div class="fit">
          <div
            :class="{ 'dropZone__content-uploaded': !!storedFile && !multiple }"
            class="absolute-full flex flex-center row no-wrap q-col-gutter-x-sm items-center"
          >
            <div
              class="ellipsis"
              :class="{ 'text-white': $q.dark.isActive && !storedUrl }"
            >
              {{ storedFile && !multiple ? storedFile.name : dropTitle }}
              <span v-if="multiple || (dense && !storedFile)">
                {{ $t('components.replaceAssetDialog.lowercaseOr') }}
                <span
                  class="text-primary cursor-pointer"
                  :class="{ 'cursor-not-allowed': storedUrl }"
                  @click="!storedUrl && $refs.fileInput.pickFiles()"
                >
                  {{ $t('components.replaceAssetDialog.upload') }}
                </span>
              </span>
            </div>
            <div v-if="storedFile && !isLoading && !multiple">
              <q-icon
                name="close"
                color="grey-5"
                class="cursor-pointer"
                @click="$emit('update:stored-file', null);$refs.imgInput?.resetValidation()"
              />
            </div>
          </div>
          <img
            v-if="previewImg"
            :src="previewImg"
            :class="{ 'fit': !dense, 'dropZone__dense-img full-width': dense }"
          >
        </div>
      </div>
      <!-- preview multi imgs -->
      <div
        v-if="storedFile && multiple"
        class="row col scroll"
        style="max-height:150px;"
      >
        <div
          v-for="(img, index) in previewImgsArray"
          :key="index"
          class="col-3 q-pa-xs"
        >
          <q-img
            :src="img"
            :ratio="16/9"
          >
            <div class="previewImg row fit justify-end">
              <div>
                <preset-q-btn
                  round
                  color="white"
                  flat
                  size="xs"
                  icon="close"
                  @click="$emit('remove', index)"
                />
              </div>
            </div>
          </q-img>
        </div>
      </div>
    </div>
    <!-- or -->
    <div
      class="col-1 column no-wrap q-mb-md"
      :class="{ 'hidden': multiple || dense }"
    >
      <div class="q-pb-md row no-wrap items-center text-grey-5 q-gutter-x-sm">
        <q-separator class="col" />
        <div>{{ $t('components.locationTargetRegion.or') }}</div>
        <q-separator class="col" />
      </div>
      <!-- upload -->
      <div
        :class="{ 'hidden': multiple || dense }"
        class="row no-wrap q-col-gutter-x-sm items-center justify-center"
      >
        <div>
          <q-file
            ref="fileInput"
            :model-value="storedFile"
            type="file"
            :multiple="multiple"
            accept="image/*"
            class="hidden"
            @update:model-value="dropHandler"
          />
          <preset-q-btn
            outline
            :disabled="Boolean(storedUrl) || isLoading"
            color="primary"
            icon="save_alt"
            :label="$t('components.replaceAssetDialog.upload')"
            @click="$refs.fileInput.pickFiles()"
          />
        </div>
        <div
          v-if="storedFile"
          class="ellipsis"
        >
          {{ storedFile.name }}
        </div>
      </div>
    </div>
    <!-- or & input -->
    <div
      v-if="showInput"
      class="q-pa-xs col"
    >
      <div class="q-pb-md row no-wrap items-center text-grey-5 q-gutter-x-sm ">
        <q-separator class="col" />
        <div>{{ $t('components.locationTargetRegion.or') }}</div>
        <q-separator class="col" />
      </div>
      <q-input
        ref="imgInput"
        :model-value="storedUrl"
        type="url"
        :disable="(storedFile && storedFile.length > 0) || isLoading"
        outlined
        rounded
        :hint="`${$t('components.replaceAssetDialog.supported')}jpg, jpeg, png, gif, svg, webp`"
        :rules="[
          v => matchRegex(v) || $t('components.replaceAssetDialog.fileNoSupport')
        ]"
        clearable
        clear-icon="close"
        :label="isVideoAllowed ? $t('components.replaceAssetDialog.urlVideoInput') : $t('components.replaceAssetDialog.urlImgInput')"
        class="q-pt-sm col"
        @update:model-value="$emit('update:stored-url', $event)"
        @clear="$emit('update:stored-url', null)"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
const regex = /[\w-]+\.(jpg|jpeg|png|gif|svg|webp)/ig
export default {
  name: 'FileTransferer',
  props: {
    storedFile: [File, Array],
    storedUrl: String,
    currentFolderId: Number,
    dropTitle: String,
    multiple: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    isVideoAllowed: { type: Boolean, default: false },
    showInput: { type: Boolean, default: true },
    outlined: { type: Boolean, default: true },
    filled: { type: Boolean, default: false },
    dense: { type: Boolean, default: false }
  },
  emits: ['update:stored-file', 'remove', 'update:stored-url'],
  setup (props, { emit }) {
    const previewImg = computed(() => {
      if (!props.multiple) return props.storedFile && URL.createObjectURL(props.storedFile)
      return ''
    })
    const previewImgsArray = computed(() => props.storedFile?.map(i => URL.createObjectURL(i)))

    const dropHandler = (evt) => { // get file from input
      evt?.preventDefault?.()
      if (props.storedUrl) return
      if (evt instanceof File) evt = [evt]
      const imageArr = cloneDeep(props.storedFile) || []
      if (evt?.dataTransfer?.files || evt?.target?.files || evt?.length > 0) {
        if (props.multiple) { // loop through array
          [...(evt?.dataTransfer?.files || evt?.target?.files || evt)]?.forEach((file) => {
            if (file.type.startsWith('image/', 0)) imageArr.push(file)
          })
          emit('update:stored-file', imageArr)
        } else {
          [...(evt?.dataTransfer?.files || evt?.target?.files || evt)]?.forEach((file) => {
            if (file.type.startsWith('image/', 0)) emit('update:stored-file', file)
          })
        }
      }
    }
    const matchRegex = (val) => { // match file types
      if (val) return val.match(regex)
      return true
    }
    return {
      previewImg,
      previewImgsArray,
      matchRegex,
      dropHandler
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-img__content > div {
  background: transparent;
  color: black;
}

.q-img__content .previewImg {
  background: rgba(0, 0, 0, 0.47);
  padding: 0px !important;
  width: 100%;
  height: 100%;
}

.dropZone {
  position: relative;
  min-height: 200px!important;
  &__dense-img {
    max-height: 200px!important;
  }
    &__outlined {
    outline: 3px dashed $grey-4;
    border-radius: 24px;
  }

  &__disabled {
    color: $grey-5;
    background: $grey-3;
  }

  &__content-uploaded {
    background: rgba(0, 0, 0, 0.6) !important;
    color: white !important;
  }
}
</style>
