import PresetQBtn from 'components/presets/PresetQBtn.js'
import PresetQBtnDropdown from 'components/presets/PresetQBtnDropdown.js'
import PresetQTab from 'components/presets/PresetQTab.js'
import PresetQPage from 'components/presets/PresetQPage.js'

export default ({ Vue }) => {
  Vue.component('PresetQBtn', PresetQBtn)
  Vue.component('PresetQBtnDropdown', PresetQBtnDropdown)
  Vue.component('PresetQTab', PresetQTab)
  Vue.component('PresetQPage', PresetQPage)
}
