import { Dialog } from 'quasar'
import EULADialog from './EULADialog.vue'
import { getCurrentInstance } from 'vue'

export const createEULADialog = () => {
  const instance = getCurrentInstance()?.proxy
  return Dialog.create({
    component: EULADialog,
    parent: instance,
    persistent: true
  })
}
