<template>
  <div
    id="q-app"
    class="bg-default-tertiary"
  >
    <template v-if="$store.state.system.loaded">
      <router-view />
    </template>

    <template v-else>
      <inner-loading :showing="true" />
    </template>
  </div>
</template>

<script>
import InnerLoading from 'components/InnerLoading.vue'
import CreationDialog from './pages/CreationDialog.vue'
import { Dialog } from 'quasar'

export default {
  name: 'App',
  components: { InnerLoading },
  events: {
    // Handles all logout attempts...
    'app.logout' () {
      this.$store.dispatch('auth/logout')
    },
    // Handles all routing...
    'app.route' (name) {
      this.debug(`Routing to "${name}"`)
      if (this.$route.name !== name) this.$router.push({ name }).catch(console.warn)
    },
    // Handles new content creation...
    'app.new' (name, config = {}) {
      this.createDialog = true
      this.createName = name
      this.config = config
      this.debug('create', name, config)
      Dialog.create({
        component: CreationDialog,
        parent: this,
        componentProps: {
          name,
          config
        }
      })
    }
  },
  data () {
    return {
      createDialog: false,
      createName: null,
      supportToggle: false
    }
  },
  mounted () {
    this.debug('Hydrating...')
    this.$store.dispatch('auth/initApp')
  }
}
</script>
