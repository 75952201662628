<template>
  <div :class="{ 'row full-width': !showBlocked }">
    <div
      class="row q-col-gutter-x-md"
      :class="{ 'col': !showBlocked, 'q-px-md': showBlocked }"
    >
      <!-- iab.selected -->
      <div
        class="q-py-md"
        :class="{ 'col': !showBlocked, 'col-xs-12 col-sm-6': showBlocked }"
      >
        <q-select
          ref="selectRef_iab"
          :model-value="sortedSelectedItems(tagState.iab.selected.items)"
          class="col-xs-12 col-sm-6"
          use-input
          input-debounce="0"
          :outlined="outlined"
          :filled="filled"
          multiple
          use-chips
          map-options
          option-label="value"
          option-value="id"
          :label="$t('pages.editContent.selectIABs')"
          :options="tagState.iab.selected.currentOptions"
          @filter="tagState.iab.selected.filterFn"
          @new-value="(val, done) => createKeyword(val, done, 'iab', false)"
          @update:model-value="onTagsChange($event, 'iab', false)"
        >
          <template #no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
          <template #selected-item="scope">
            <color-coded-tag-chip
              :tag="scope.opt"
              @remove="removeSelected(scope.opt, tagState.iab.selected)"
            />
          </template>
        </q-select>
      </div>
      <!-- iab.blocked -->
      <div
        v-if="showBlocked"
        class="col-xs-12 col-sm-6 q-py-md"
      >
        <q-select
          ref="blockedSelectRef_iab"
          :model-value="sortedSelectedItems(tagState.iab.blocked.items)"
          class="col-xs-12 col-sm-6"
          use-input
          input-debounce="0"
          :outlined="outlined"
          :filled="filled"
          multiple
          use-chips
          map-options
          option-label="value"
          option-value="id"
          :label="$t('pages.editContent.selectBlacklistedIABs')"
          :options="tagState.iab.blocked.currentOptions"
          @filter="tagState.iab.blocked.filterFn"
          @new-value="(val, done) => createKeyword(val, done, 'iab', true)"
          @update:model-value="onTagsChange($event, 'iab', true)"
        >
          <template #no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
          <template #selected-item="scope">
            <color-coded-tag-chip
              :tag="scope.opt"
              @remove="removeSelected(scope.opt, tagState.iab.blocked)"
            />
          </template>
        </q-select>
      </div>
    </div>
    <div
      class="row q-col-gutter-x-md"
      :class="{ 'col q-ml-sm': !showBlocked, 'q-px-md': showBlocked }"
    >
      <!-- keyword.selected -->
      <div
        class="q-py-md"
        :class="{ 'col': !showBlocked, 'col-xs-12 col-sm-6': showBlocked }"
      >
        <q-select
          ref="selectRef_keyword"
          :model-value="sortedSelectedItems(tagState.keyword.selected.items)"
          class="col-xs-12 col-sm-6"
          use-input
          input-debounce="0"
          :outlined="outlined"
          :filled="filled"
          multiple
          use-chips
          map-options
          option-label="value"
          option-value="id"
          :label="$t('pages.editContent.selectKeywords')"
          :options="tagState.keyword.selected.currentOptions"
          @filter="tagState.keyword.selected.filterFn"
          @new-value="(val, done) => createKeyword(val, done, 'keyword', false)"
          @update:model-value="onTagsChange($event, 'keyword', false)"
        >
          <template #no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
          <template #selected-item="scope">
            <color-coded-tag-chip
              :tag="scope.opt"
              @remove="removeSelected(scope.opt, tagState.keyword.selected)"
            />
          </template>
        </q-select>
      </div>
      <!-- keyword.blocked -->
      <div
        v-if="showBlocked"
        class="col-xs-12 col-sm-6 q-py-md"
      >
        <q-select
          ref="blockedSelectRef_keyword"
          :model-value="sortedSelectedItems(tagState.keyword.blocked.items)"
          class="col-xs-12 col-sm-6"
          use-input
          input-debounce="0"
          :outlined="outlined"
          :filled="filled"
          multiple
          use-chips
          map-options
          option-label="value"
          option-value="id"
          :label="$t('pages.editContent.selectBlacklistedKeywords')"
          :options="tagState.keyword.blocked.currentOptions"
          @filter="tagState.keyword.blocked.filterFn"
          @new-value="(val, done) => createKeyword(val, done, 'keyword', true)"
          @update:model-value="onTagsChange($event, 'keyword', true)"
        >
          <template #no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
          <template #selected-item="scope">
            <color-coded-tag-chip
              :tag="scope.opt"
              @remove="removeSelected(scope.opt, tagState.keyword.blocked)"
            />
          </template>
        </q-select>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, unref, reactive } from 'vue'
import ColorCodedTagChip from 'components/Experience/ColorCodedTagChip.vue'
import { $api } from 'src/boot/axios'
import handleApiError from 'src/utils/handle-api-error'
import orderBy from 'lodash/orderBy'

export default {
  name: 'IABKeywordEditor',
  components: { ColorCodedTagChip },
  props: {
    id: [String, Number],
    content: Object,
    iabCategories: {
      required: true,
      type: Array
    },
    icon: Boolean,
    showBlocked: { type: Boolean, default: true },
    outlined: { type: Boolean, default: true },
    filled: { type: Boolean, default: false }
  },
  emits: ['update:tags', 'ready'],
  setup (props, { emit }) {
    const iabTags = ref([])
    const keywordTags = ref([])

    function createTagState ({ selected, blocked, tagOptions }) {
      const selectedCurrentOptions = ref([])
      const blockedCurrentOptions = ref([])
      const sortedSelected = computed(() => sortByScore(selected))
      const sortedBlocked = computed(() => sortByScore(blocked))
      function createFilterBy (currentOptionsRef, excludeList) {
        const filtered = computed(() => unref(tagOptions).filter(opt => !unref(excludeList).some(i => i.id === opt.id)))
        return function (val, update) {
          if (val === '') {
            update(() => {
              currentOptionsRef.value = unref(filtered)
            })
          }
          update(() => {
            const needle = val.toLowerCase()
            currentOptionsRef.value = unref(filtered).filter(v => v.value.toLowerCase().indexOf(needle) > -1)
          })
        }
      }
      function sortByScore (arr) {
        return orderBy(
          arr.value,
          val => val.tagLink.score ? val.tagLink.score : 0,
          'desc'
        )
      }
      return {
        selected: {
          items: sortedSelected,
          currentOptions: selectedCurrentOptions,
          filterFn: createFilterBy(selectedCurrentOptions, blocked)
        },
        blocked: {
          items: sortedBlocked,
          currentOptions: blockedCurrentOptions,
          filterFn: createFilterBy(blockedCurrentOptions, selected)
        }
      }
    }

    // filter and shape content tags data into keywords & iab
    const filterTags = (tagType, isBlacklist) => {
      return props.content?.tags?.filter(tag => {
        return tag.type === tagType && tag.tagLink.blocked === isBlacklist
      }) || []
    }

    const tagState = reactive({
      iab: createTagState({
        selected: computed(() => filterTags('iab', false)),
        blocked: computed(() => filterTags('iab', true)),
        tagOptions: iabTags,
        tagType: 'iab'
      }),
      keyword: createTagState({
        selected: computed(() => filterTags('keyword', false)),
        blocked: computed(() => filterTags('keyword', true)),
        tagOptions: keywordTags,
        tagType: 'keyword'
      })
    })

    function createTagComponentRef () {
      return {
        selected: ref(null),
        blocked: ref(null)
      }
    }

    const tagComponentRefs = {
      iab: createTagComponentRef(),
      keyword: createTagComponentRef()
    }
    /**
     * A patch for vue2 composition api since it does not support nested component refs
     */
    const tagComponentRefsForTemplate = Object.keys(tagComponentRefs).map(key => {
      return {
        [`selectRef_${key}`]: tagComponentRefs[key].selected,
        [`blockedSelectRef_${key}`]: tagComponentRefs[key].blocked
      }
    }).reduce((obj, curr) => {
      return { ...obj, ...curr }
    }, {})

    // used with emit('update:tags') to aggregate all tags
    const getAllSelectedTags = (newTagArr, newTagType, newIsBlacklist) => {
      const otherTagType = newTagType === 'iab' ? 'keyword' : 'iab'
      return [
        ...newTagArr,
        ...tagState[newTagType][newIsBlacklist ? 'selected' : 'blocked'].items,
        ...tagState[otherTagType].blocked.items,
        ...tagState[otherTagType].selected.items
      ]
    }

    // populate user tags into destination ref
    const fetchTags = async ({ endpoint, destination }) => {
      await handleApiError($api.get(endpoint, { params: { _limit: -1 } }) // FIXME - should ideally lazy load based on user search
        .then(({ data }) => {
          destination.value = data.map(tag => {
            return {
              ...tag,
              tagLink: {
                score: null,
                blocked: null
              }
            }
          })
        })
      )
    }

    const setIsBlacklist = (arr, isBlacklist) => {
      arr.forEach(val => (val.tagLink.blocked = isBlacklist))
    }

    // handle inputs
    const onTagsChange = (tags, tagType, isBlacklist) => {
      setIsBlacklist(tags, isBlacklist)
      const inputRef = tagComponentRefs[tagType][isBlacklist ? 'blocked' : 'selected']
      inputRef.value.inputValue = ''
      emit('update:tags', getAllSelectedTags(tags, tagType, isBlacklist))
    }

    const removeSelected = (tag, source) => {
      const newTags = source.items.filter(x => x.id !== tag.id)
      emit('update:tags', getAllSelectedTags(newTags, tag.type, tag.tagLink.blocked))
    }

    // handle user typed keyword creation
    async function createKeyword (val, done, tagType, isBlacklist) {
      const tagTypeState = tagState[tagType][isBlacklist ? 'blocked' : 'selected']
      const existingTag = tagType === 'iab'
        ? iabTags.value.find(x => x.value.toLowerCase() === val.toLowerCase())
        : keywordTags.value.find(x => x.value.toLowerCase() === val.toLowerCase()
        )
      if (existingTag) {
        // if tag exists, add existing tag to selected
        const tags = [existingTag, ...tagTypeState.items]
        onTagsChange(tags, tagType, isBlacklist)
        return
      }
      if (tagType === 'iab' || !val.length > 0) return
      const tagObj = {
        displayName: val,
        source: 'user',
        type: 'keyword',
        value: val,
        blocked: isBlacklist
      }
      const res = await handleApiError($api.post('/tags', tagObj))
      const tagData = {
        ...res.data,
        tagLink: {
          blocked: isBlacklist,
          score: null
        }
      }
      const inputRef = tagComponentRefs[tagType][isBlacklist ? 'blocked' : 'selected']
      inputRef.value.inputValue = ''
      fetchAll()
      emit('update:tags', [
        tagData,
        ...tagState.keyword.selected.items,
        ...tagState.keyword.blocked.items,
        ...tagState.iab.selected.items,
        ...tagState.iab.blocked.items
      ])
    }

    const sortedSelectedItems = (arr) => {
      return arr.sort((a, b) => {
        if (a.tagLink.score !== null && b.tagLink.score !== null) {
          return b.tagLink.score - a.tagLink.score
        } else if (a.tagLink.score !== null) {
          return -1
        } else if (b.tagLink.score !== null) {
          return 1
        } else {
          return a.value.localeCompare(b.value)
        }
      })
    }

    // FIXME
    // should ideally lazy load based on user search
    const fetchAll = async () => {
      try {
        await Promise.all([
          fetchTags({ endpoint: '/currentuser/tags?source=user&type=keyword', destination: keywordTags }),
          fetchTags({ endpoint: '/currentuser/tags?source=system&type=iab', destination: iabTags })
        ])
      } catch (err) {
        console.error(err)
      }
      emit('ready')
    }
    // init by fetching all user tags
    fetchAll()

    return {
      // input refs
      ...tagComponentRefsForTemplate,
      // functions
      createKeyword,
      onTagsChange,
      removeSelected,
      // data
      tagState,
      sortedSelectedItems
    }
  }
}
</script>
