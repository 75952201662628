import { DateTime } from 'luxon'
import { useStore } from 'vuex'

/**
 * @returns {{ presetDateTime: (date: string) => import('luxon').DateTime }}
 */
export function usePresetDateTime () {
  const store = useStore()
  const currentZone = store?.getters['adminapp/getTimeZone']
  const presetDateTime = (date) => DateTime.fromISO(date, { zone: currentZone })
  return {
    presetDateTime
  }
}
