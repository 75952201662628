import axios from 'axios'
export default {
  // A full hydration system that loads app and user data, then marks the app as loaded...
  hydrate (context, payload) {
    const endpoints = {
      user: context.state.system.api.user,
      experience: context.state.system.api.experience
    }
    axios.get(`${endpoints.experience}/${payload.experience}`).then((result) => {
      console.log('hydration', result.data.config)
      context.commit('replaceApp', result.data.config)
      context.commit('loaded')
    })
  }
}
