import merge from 'src/utils/merge'

export default {
  updateUser (state, payload) {
    merge(state.user, payload)
  },
  updateApp (state, payload) {
    merge(state.app, payload)
  },
  replaceUser (state, payload) {
    state.user = payload
  },
  replaceApp (state, payload) {
    state.app = payload
  },
  loaded (state, status = true) {
    state.system.loaded = status
  },
  systemChangeAPI (state, payload) {
    state.system.api = payload
  }
}
