import axios from 'axios'
import qs from 'qs'

/** @type { import('axios').AxiosInstance } */
let $api
/** @type { import('axios').AxiosInstance } */
let $publicApi2
/** @type { import('axios').AxiosInstance } */
let $publicApi

export default ({ app, store }) => {
  app.config.globalProperties.$axios = axios

  const qsInterceptor = (config) => {
    return {
      ...config,
      ...(config.$qs ? { paramsSerializer: p => qs.stringify(p) } : {})
    }
  }

  $api = axios.create()
  $publicApi2 = axios.create({
    baseURL: store.getters['adminapp/apiUrl2']
  })
  $publicApi = axios.create({
    baseURL: store.getters['adminapp/apiUrl']
  })
  app.config.globalProperties.$publicApi = $publicApi
  app.config.globalProperties.$publicApi = $publicApi2
  $api.interceptors.request.use((config) => {
    return {
      ...config,
      baseURL: config.baseURL || store.getters['adminapp/apiUrl'],
      headers: Object.assign({}, store.getters['auth/authHeaders'], config.headers)
    }
  })
  $api.interceptors.request.use(qsInterceptor)
  $publicApi.interceptors.request.use(qsInterceptor)
  $publicApi2.interceptors.request.use(qsInterceptor)
  app.config.globalProperties.$api = $api
}

export {
  $api,
  $publicApi,
  $publicApi2
}
