<script setup lang="ts">
import { useResourceDialog } from 'src/composables/use-dialog'
import { useAttrs, ref } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'
import EULAHeader from './EULAHeader.vue'
import EULANotAccepted from './EULANotAccepted.vue'
import { useStore } from 'vuex'
import { $api } from 'src/boot/axios'
import { $notify } from 'src/boot/notify'
const emit = defineEmits(['ok'])
defineProps<{
  dialogProps?: object | null
}>()
const store = useStore()

const dialog = ref<null|object>(null)
const pdfEmbed = ref<null|object>(null)
const attrs = useAttrs()
const dialogFn = useResourceDialog({ dialog, selection: [] }, { emit, attrs })

const agreedToTerms = ref(false)
const pdfUrl = ref('https://storage.googleapis.com/source-uploads-production/uploads/Source_Sync_Companion_EULA_dcf4e1a364/Source_Sync_Companion_EULA_dcf4e1a364.pdf')

const updateUserEULA = async () => {
  const response = await $api.put('/currentuser', { AcceptEULA: true })
  store.commit('updateUser', response.data)
  emit('ok')
  dialogFn.hide()
}
const printToPDF = async () => {
  if (pdfEmbed.value) {
    try {
      await pdfEmbed.value.download('SourceSync-Companion EULA')
    } catch (error) {
      $notify.error()
      console.error('Error downloading PDF:', error)
    }
  }
}
</script>

<template>
  <q-dialog
    ref="dialog"
    v-bind="{...$attrs, ...(dialogProps || {})}"
    class="sd-dialog--lg"
    persistent
  >
    <q-card class="roundedBordersFull overflow-hidden fit bg-dark-blue-custom column no-wrap">
      <!-- header -->
      <EULAHeader
        class="col-xs-2 col-sm-1 col-md-1 col-lg-1 col-xl-1"
        :class="{ 'q-mb-md': $q.screen.gt.xs }"
        :agreed-to-terms="agreedToTerms"
        @download="printToPDF"
      />
      <q-card-section
        class="col-xs-9 col-sm-9 col-md-10 col-lg-10 col-xl-10 text-black scroll"
        :class="{ 'bg-white': !agreedToTerms }"
      >
        <!-- pdf -->
        <div v-if="!agreedToTerms">
          <VuePdfEmbed
            ref="pdfEmbed"
            :source="pdfUrl"
          />
        </div>
        <!-- not accepted -->
        <EULANotAccepted
          v-else
          @update:terms="agreedToTerms = false"
        />
      </q-card-section>
      <q-card-section
        class="row col justify-center items-center"
      >
        <div class="row full-width justify-around">
          <preset-q-btn
            v-if="!agreedToTerms"
            size="$md"
            color="white"
            icon="close"
            class="text-black roundedBordersFull"
            label="Disagree"
            @click="agreedToTerms = true"
          />
          <preset-q-btn
            v-if="!agreedToTerms"
            size="$md"
            color="white"
            icon="check"
            class="text-black roundedBordersFull"
            label="Agree"
            @click="updateUserEULA"
          />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<style lang="scss" scoped>
.bg-dark-blue-custom {
  background-color: #1d2233;
}
</style>
