import { ref, unref } from 'vue'
import { toggleSelect } from 'src/utils/resource'

export default function useDialog (dialogRef) {
  function hide () {
    unref(dialogRef).hide()
  }
  return {
    show () {
      unref(dialogRef).show()
    },
    hide,
    onCancel () {
      hide()
    }
  }
}

export function useProductionEditDialog ({ dialog: dialogRef }, { emit }) {
  const dialog = useDialog(dialogRef)
  function submit (data) {
    emit('ok', data)
    dialog.hide()
  }

  function hide () {
    dialog.hide()
  }
  return {
    ...dialog,
    submit,
    hide
  }
}

export function useResourceDialog ({ dialog: dialogRef, selection }, { emit, attrs }) {
  const dialog = useDialog(dialogRef)
  const selected = ref([])
  function onItemClick (value) {
    if (attrs?.['onItem-click']) attrs['onItem-click'](value)
    else if (selection.value !== 'multiple') {
      emit('ok', value)
      dialog.hide()
    } else selected.value = toggleSelect(selected.value, value, 'id')
  }
  function submit (data) {
    if (selection.value === 'single') {
      emit('ok', data?.[0])
    } else emit('ok', data)
    dialog.hide()
  }
  return {
    ...dialog,
    selected,
    submit,
    onItemClick
  }
}
