import { createApp, configureCompat } from 'vue'
import { Quasar, Notify, Dialog, LoadingBar, LocalStorage } from 'quasar'
import { boot } from './legacy-boot'
import App from './App.vue'

import createStore from 'src/store/index'
import createRouter from 'src/router/index'
import { createPinia } from 'pinia'

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
// import '@quasar/extras/mdi-v7/mdi-v7.css'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'

// Import Quasar css
import 'quasar/src/css/index.sass'
import './css/app.styl'

configureCompat({
  MODE: 3,
  INSTANCE_LISTENERS: false,
  INSTANCE_SCOPED_SLOTS: 'suppress-warning',
  INSTANCE_SET: 'suppress-warning',
  COMPONENT_FUNCTIONAL: 'suppress-warning',
  FILTERS: false,
  ATTR_FALSE_VALUE: 'suppress-warning',
  ATTR_ENUMERATED_COERCION: 'suppress-warning'
})

const pinia = createPinia()
const app = createApp(App)

const store = typeof createStore === 'function'
? createStore({ app })
: createStore

const router = typeof createRouter === 'function'
? createRouter({ app, store })
: createRouter

app.use(pinia)

app.use(Quasar, {
  plugins: {
    Notify,
    Dialog,
    LoadingBar,
    LocalStorage
  }
})

boot({ app, store, router }).then(() => {
  app.mount('#q-app')
})

export {
  app
}
