import { i18n } from 'src/boot/i18n'

const routes = [
  // Logged-in routes...
  {
    path: '/',
    component: () => import('layouts/Simple.vue'),
    props: true,
    meta: { requiresLogin: true },
    children: [
      {
        name: 'redirect',
        path: '/redirect',
        component: () => import('pages/Redirect.vue'),
        props: route => ({ ...route.params })
      },
      {
        name: 'loading',
        path: '/loading',
        component: () => import('pages/Loading.vue')
      },

      { name: 'home', path: '/', component: () => import('pages/Home.vue'), props: true, meta: { title: i18n.t('home.title') } },
      { path: '/home', component: () => import('pages/Home.vue'), meta: { title: i18n.t('home.title') } },
      { name: 'pulse', path: 'pulse', component: () => import('pages/Pulse.vue'), meta: { title: i18n.t('pages.pulse.heading') } },
      { name: 'assets', path: '/assets', component: () => import('pages/Images.vue'), meta: { title: i18n.t('pages.assets.heading') } },
      { name: 'account', path: '/account', component: () => import('pages/Account.vue'), meta: { title: 'Account' } },
      { name: 'live', path: '/live', component: () => import('pages/Live.vue'), meta: { title: 'Live' } },

      // Production pages
      { name: 'production', path: '/production', component: () => import('pages/Production.vue'), props: true, meta: { title: i18n.t('pages.production.heading') } },
      { name: 'edit-production', path: '/production/:id', component: () => import('pages/EditProduction/EditProduction.vue'), props: true },
      // Distribution pages
      { name: 'distribution', path: '/distribution', component: () => import('pages/Content.vue'), meta: { title: i18n.t('pages.content.heading') } },
      { name: 'edit-distribution', path: '/production/:prodId/distribution/:id', component: () => import('src/pages/EditContent/EditContent.vue'), props: true },
      // Live Distribution pages
      { name: 'liveProduction', path: '/live-production', component: () => import('pages/Content.vue'), meta: { title: i18n.t('pages.content.liveHeading') } },
      { name: 'edit-liveProduction', path: '/live-production/:id', component: () => import('src/pages/EditContent/EditContent.vue'), props: true },
      { name: 'edit-liveProduction.timeline', path: '/live-production/:id/timeline', component: () => import('pages/EditLiveContentTimeline.vue'), props: true },
      // Campaign pages
      { name: 'campaign', path: '/campaigns', component: () => import('pages/Campaigns.vue'), meta: { title: i18n.t('pages.campaign.heading') } },
      { name: 'edit-campaign', path: '/campaign/:id', component: () => import('pages/Campaign/CampaignOverview.vue'), props: true },
      // Activation pages
      { name: 'activation', path: '/activation', component: () => import('pages/Metacontent.vue'), meta: { title: i18n.t('pages.metacontent.heading') } },
      { name: 'edit-activation', path: '/activation/:id', component: () => import('pages/MetacontentOverview.vue'), props: true },
      { name: 'edit-production-activation', path: '/production/:prodId/activation/:id', component: () => import('pages/MetacontentOverview.vue'), props: true },
      // Interactive pages
      { name: 'interactive', path: '/advertising', component: () => import('src/pages/Interactives.vue'), meta: { title: i18n.t('pages.interactive.heading') } },
      { name: 'edit-interactive', path: '/advertising/:id', component: () => import('pages/EditInteractive.vue'), props: true },
      // Property
      { name: 'property', path: '/property', component: () => import('pages/Property.vue'), props: true }
    ]
  },
  { // Timelines
    path: '/',
    component: () => import('layouts/Nothing.vue'),
    props: true,
    meta: { requiresLogin: true },
    children: [
      { name: 'edit-production.timeline', path: '/production/:id/timeline', component: () => import('pages/EditContentTimeline/EditContentTimeline.vue'), props: props => ({ ...props.params, resourceType: 'productions' }) },
      { name: 'edit-distribution.timeline', path: '/production/:prodId/distribution/:id/timeline', component: () => import('pages/EditContentTimeline/EditContentTimeline.vue'), props: true }
    ]
  },
  // Public routes...
  {
    path: '/',
    component: () => import('layouts/Nothing.vue'),
    children: [
      { name: 'login', path: '/login', component: () => import('pages/Login.vue'), meta: { title: i18n.t('layout.login') } },
      { name: 'reset-password', props: true, path: '/reset-password', component: () => import('pages/ResetPassword.vue'), meta: { title: 'Reset password' } },
      { name: 'sso-redirect', props: true, path: '/connect/:provider/redirect', component: () => import('pages/SsoRedirect.vue') }
    ]
  },
  // Everything else...
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('layouts/Nothing.vue')
  }
]

export default routes
