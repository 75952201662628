<template>
  <div>
    <div>
      <!-- header -->
      <q-card-section
        v-if="showHeader"
        class="row items-center"
      >
        <div class="text-h6">
          {{ $t('pages.assets.uploadImages') }}
        </div>
        <q-space />
        <q-btn
          v-close-popup
          icon="close"
          flat
          round
          dense
        />
      </q-card-section>
      <!-- upload progress viewer -->
      <div
        v-if="isLoading"
        class="uploader-block"
      >
        <upload-progress-viewer
          :loading="loadProgress"
          :total-files="totalFiles"
          multiple
          class="q-px-md"
        />
      </div>
      <!-- file transferer -->
      <div
        v-else
        :class="{ 'q-pa-md': showHeader }"
      >
        <file-tranferer
          class="full-width"
          :multiple="isMultiple"
          :stored-file="storedFile"
          :stored-url="storedUrl"
          :loading="loadProgress"
          :is-loading="isLoading"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          is-video-allowed
          :current-folder-id="currentFolderId"
          :drop-title="$t('components.uploadProgressViewer.dragAndDrop')"
          :show-input="showInput"
          @update:stored-file="storedFile = $event"
          @update:stored-url="storedUrl = $event"
          @remove="removeStoredFile"
        />
      </div>
    </div>
    <!-- btns -->
    <q-card-actions
      v-if="!isLoading && showActions"
      class="row justify-end"
    >
      <q-btn
        v-close-popup
        flat
        color="primary"
        label="Cancel"
        class="q-mr-sm"
      />

      <q-btn
        color="primary"
        label="Next"
        rounded
        class="q-px-sm"
        :disable="!(storedFile && storedFile.length) && !storedUrl"
        @click="onUploadAssets"
      />
    </q-card-actions>
  </div>
</template>

<script>
import FileTranferer from './FileTranferer.vue'
import UploadProgressViewer from './UploadProgressViewer.vue'
import { computed, ref } from 'vue'
import { useUpdateFile } from 'src/composables/use-update-file'
export default {
  components: {
    FileTranferer,
    UploadProgressViewer
  },
  props: {
    currentFolderId: Number,
    showInput: { type: Boolean, default: true },
    showActions: { type: Boolean, default: true },
    showHeader: { type: Boolean, default: true },
    isMultiple: { type: Boolean, default: true },
    outlined: { type: Boolean, default: true },
    filled: { type: Boolean, default: false },
    dense: { type: Boolean, default: false }
  },
  emits: ['ok'],
  setup (props, { emit }) {
    const storedFile = ref(null)
    const storedUrl = ref(null)
    const isLoading = ref(false)

    const totalFiles = computed(() => Array.isArray(storedFile.value) ? storedFile.value.length : storedUrl.value ? 1 : 0)

    const { loading, uploadAssets } = useUpdateFile()
    const loadProgress = computed(() => loading)
    const removeStoredFile = (index) => {
      storedFile.value = storedFile.value.filter((item, i) => i !== index)
    }

    const onUploadAssets = async () => { // clear previous values & update resources
      isLoading.value = true
      const res = await uploadAssets({ storedFile, storedUrl, currentFolderId: props.currentFolderId })
      isLoading.value = false
      storedUrl.value = ''
      storedFile.value = null
      emit('ok')
      return res
    }

    return {
      // vars
      storedFile,
      storedUrl,
      loadProgress,
      isLoading,
      totalFiles,
      // methods
      removeStoredFile,
      onUploadAssets
    }
  }
}
</script>

<style scoped lang="stylus">
.q-file-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
</style>
