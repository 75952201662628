import { Dark } from 'quasar'

export default {
  setCurrentTenantId (state, payload) {
    state.currentTenantId = payload
  },
  setSettings (state, payload) {
    state.settings = payload
  },
  setRegions (state, payload) {
    state.targeting.regions = payload
  },
  setMetros (state, payload) {
    state.targeting.metros = payload
  },
  setCities (state, payload) {
    state.targeting.cities = payload
  },
  setStates (state, payload) {
    state.targeting.states = payload
  },
  setDarkMode (state, payload) {
    state.darkMode = payload
    Dark.set(payload)
  },
  updateRoute (state, payload) {
    state.routes = payload
  }
}
