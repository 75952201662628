import { defaultTheme } from 'src/utils/constants'
// import { colors } from 'quasar'
// import get from 'lodash/get' // FIXME -- commenting out due to migration comment

export function toInteger (value, unit) {
  if (value === null || typeof value === 'undefined' || typeof value === 'number') return value
  const match = (new RegExp('^(.*)(' + [unit].join('|') + ')$')).exec(value)
  if (!match) return null
  const parsedValue = parseInt(match[1])
  return isNaN(parsedValue) ? null : parsedValue
}

export function changeTheme (theme) {
  Object.keys(defaultTheme).forEach(color => {
    // TODO [Migration]
    // colors.setBrand(color, get(theme, color, defaultTheme[color]))
  })
}
