import { DateTime } from 'luxon'

export function compareIsoDate (a, b, opts) {
  const _a = DateTime.fromISO(a, opts).toMillis()
  const _b = DateTime.fromISO(b, opts).toMillis()
  if (_a === _b) return 0
  return (_a > _b) ? 1 : -1
}

export function isoIsWithinRange (val, min, max, opts) {
  return isoIsAfterOrEqual(val, min, opts) && isoIsBeforeOrEqual(val, max, opts)
}

export function isoIsBefore (a, b, opts) {
  return compareIsoDate(a, b, opts) < 0
}

export function isoIsAfter (a, b, opts) {
  return compareIsoDate(a, b, opts) > 0
}

export function isoIsBeforeOrEqual (a, b, opts) {
  return !isoIsAfter(a, b, opts)
}

export function isoIsAfterOrEqual (a, b, opts) {
  return !isoIsBefore(a, b, opts)
}

/**
 * @template T
* @param {{ start: import('luxon').DateTime, end: import('luxon').DateTime, unit: keyof import('luxon').DateTime, format?: (v: import('luxon').DateTime) => T }} dates items containing DateTime objects
* @returns {T[]} an array of dates in the format 'MM/dd' for the current week.
*/
export const fillDates = ({ start, end, unit = 'day', format }) => {
  if (!start || !end) throw new Error('start and end are required')
  const values = []
  let currentDate = start
  while (currentDate <= end) {
    values.push(format ? format(currentDate) : currentDate)
    currentDate = currentDate.plus({ [unit]: 1 })
  }
  return values
}
