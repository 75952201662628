import { rtdb, auth } from './firebase/database'
import { rtdbPlugin } from 'vuefire'
import { signInWithCustomToken, signOut } from 'firebase/auth'

export const signInWithToken = async (customToken) => {
  try {
    const userCredential = await signInWithCustomToken(auth, customToken)
    const user = userCredential.user
    return user
  } catch (error) {
    console.error('Error during custom token sign-in:', error)
    throw error
  }
}

export const signOutUser = async () => {
  try {
    await signOut(auth)
  } catch (error) {
    console.error('Error signing out:', error)
    throw error
  }
}

export default ({ app }) => {
  app.use(rtdbPlugin)

  app.config.globalProperties.$firebase = app
  app.config.globalProperties.$rtdb = rtdb
  app.config.globalProperties.$firebaseAuth = auth
}
