import { Notify } from 'quasar'
import get from 'lodash/get'
import { i18n } from 'src/boot/i18n'

export const notifyError = () => Notify.create({ type: 'negative', message: i18n.t('notifications.error') })

const handleApiError = async (promise, throwError = true) => {
  try {
    return await promise
  } catch (e) {
    console.error(e, get(e, 'response.data'))
    notifyError()
    if (throwError) throw e
  }
}

export default handleApiError
