export default {
  zone: 'America/New_York',
  currentTenantId: null,
  settings: null,
  targeting: {
    regions: [],
    states: [],
    metros: [],
    cities: []
  },
  darkMode: null,
  routes: { to: {}, from: {} }
}
