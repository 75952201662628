import { createStore } from 'vuex'
// import ee from 'experience-engine'

import adminapp from './adminapp'
import auth from './auth'
import ee from './legacy'

export const store = createStore(ee)
store.registerModule('adminapp', adminapp)
store.registerModule('auth', auth)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function ({ app }) {
  app.use(store)
  store.commit('systemChangeAPI', {
    base: import.meta.env.VITE_SOURCE_API_URL || 'https://api-dev.sourcesync.io',
    secondApi: import.meta.env.VITE_SOURCE_API_2_URL || 'https://api2-dev.sourcesync.io',
    gmaps_key: ''
  })
  app.config.globalProperties.$settings = store.getters['adminapp/getAppSettings']
  return store
}
