import { type App } from 'vue'

import qboot_Bootee from 'boot/ee'

import qboot_Booti18n from 'boot/i18n'

import qboot_Bootaxios from 'boot/axios'

import qboot_Bootrules from 'boot/rules'

import qboot_Bootnotify from 'boot/notify'

import qboot_BootglobalComponents from 'boot/globalComponents'

import qboot_Bootfirebase from 'boot/firebase'


// TODO [Migration]: upgrade extensions below
// import qboot_Quasarquasarappextensionqcalendarsrcbootregisterjs from '@quasar/quasar-app-extension-qcalendar/src/boot/register.js'
// import qboot_Quasarquasarappextensionqmediaplayersrcbootregisterjs from '@quasar/quasar-app-extension-qmediaplayer/src/boot/register.js'


export async function boot({
  app,
  router,
  store
}: {
  app: App,
  router: any,
  store: any
}) {
  const bootFiles = [qboot_Bootee, qboot_Booti18n, qboot_Bootaxios, qboot_Bootrules, qboot_Bootnotify, qboot_BootglobalComponents, qboot_Bootfirebase,/*  qboot_Quasarquasarappextensionqcalendarsrcbootregisterjs, qboot_Quasarquasarappextensionqmediaplayersrcbootregisterjs */]

  for (let i = 0; i < bootFiles.length; i++) {
    if (typeof bootFiles[i] !== 'function') {
      continue
    }

    try {
      await bootFiles[i]({
        app,
        router,
        store,
        Vue: app,
        // ssrContext: null,
        // redirect,
        // urlPath,
        // publicPath
      })
    }
    catch (err) {
      console.error('[Quasar] boot error:', err)
      return
    }
  }
}
