// This is just an example,
// so you can safely delete all default props below

export default {
  short: { dateStyle: 'medium' },
  long: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  }
}
