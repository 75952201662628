import alpha from 'color-alpha'
import { colors } from 'quasar'

const { getPaletteColor } = colors

export function isLightColor (rgbaString) {
  const threshold = 170
  const rgba = rgbaString.replace(/[^\d,]/g, '').split(',').map(e => parseInt(e))
  return !(rgba[0] < threshold || rgba[1] < threshold || rgba[2] < threshold)
}
export function isColor (c) {
  // eslint-disable-next-line no-undef
  const s = new Option().style
  const ci = s.color
  s.color = c
  return s.color !== ci
}
export function borderColor (color) {
  return {
    background: alpha(getPaletteColor(color), 0.2),
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: getPaletteColor(color)
  }
}
