<script>
import { QInnerLoading, QSpinnerPie, QSpinnerTail } from 'quasar'
import { defineComponent, h } from 'vue'

export default defineComponent({
  props: {
    showSpinnerPie: {
      type: Boolean,
      default: true
    }
  },
  render () {
    const childrenFn = () => {
      const children = []

      if (this.showSpinnerPie) {
        children.push(
          h(QSpinnerPie, {
            color: 'blue',
            size: '10em'
          })
        )
      } else {
        children.push(
          h('div', {
            style: {
              textAlign: 'center',
              color: 'primary'
            }
          }, [
            h(QSpinnerTail, {
              color: 'primary',
              size: '3em',
              style: {
                marginTop: '30px'
              }
            }),
            h('div', {
              style: {
                marginTop: '20px'
              }
            }, 'Please wait...')
          ])
        )
      }
      return children
    }

    return h(QInnerLoading, {}, () => childrenFn())
  }
})
</script>
