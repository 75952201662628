import { computed, unref } from 'vue'
import { Screen } from 'quasar'

export const useBtnSize = (props) => {
  const sizes = computed(() => {
    let $xl
    if (Screen.xs) $xl = 'md'
    if (Screen.sm) $xl = 'lg'
    $xl = 'xl'
    return {
      $xs: Screen.lt.lg ? 'xs' : 'sm',
      $sm: Screen.lt.xl ? 'sm' : 'md',
      $md: Screen.lt.md ? 'sm' : 'md',
      $lg: Screen.lt.md ? 'md' : 'lg',
      $xl
    }
  })
  const btnSize = computed(() => {
    const s = props.size
    if (s && unref(sizes)[s]) return unref(sizes)[s]
    return props.size
  })
  return btnSize
}
