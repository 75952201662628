import { i18n } from 'src/boot/i18n'
import messages from 'src/i18n/messages'
import { LocalStorage } from 'quasar'
import createDebug from 'src/utils/debug'
import { changeTheme } from 'src/utils/css'
import { sourcesync } from '../../../package.json'
import allSettled from 'promise.allsettled'
import { merge, cloneDeep } from 'lodash'
import axios from 'axios'

import { $publicApi, $api } from 'src/boot/axios'
const debug = createDebug.extend('store/adminapp')

const originalMsgs = cloneDeep(messages)

export default {
  async loadSettings ({ commit, getters, rootGetters, dispatch }) {
    let promise
    commit('setSettings', null)
    if (!rootGetters['auth/isLoggedIn']) {
      const tenantId = LocalStorage.getItem('debug.tenantId') || window.SourceTenantId
      const headers = tenantId ? { 'source-tenant': tenantId } : {}
      promise = $publicApi.get(`/settings/apps/${sourcesync.slug}`, { headers })
    } else {
      promise = $api.get(`/currentuser/settings/apps/${sourcesync.slug}`)
      await dispatch('loadUserSettings')
    }
    const [hydrateResult] = await allSettled([promise.then(({ data }) => data)])
    if (hydrateResult.status !== 'fulfilled') {
      console.error('Error occurred while fetching app settings.', hydrateResult.reason, hydrateResult)
    } else {
      commit('setSettings', hydrateResult.value)
      debug('Application state loaded', hydrateResult.value)
    }
  },
  async setCurrentTenant ({ commit, getters, dispatch, rootGetters }, { tenantId, noReload }) {
    if (!!tenantId && !getters.user?.organizations?.some(i => i.id === tenantId)) {
      debug('Invalid tenantId.', tenantId)
      tenantId = null
    }

    if (!noReload) {
      try {
        // Forcibly navigate away from page to trigger "beforeRouteLeave" and wait for result
        await this.$router.push({ name: 'loading' })
      } catch (err) {
        debug('Navigation was aborted.', tenantId, err)
      }
    }
    commit('setCurrentTenantId', tenantId)
    await dispatch('loadSettings')
    const $settings = rootGetters['adminapp/getAppSettings']
    if (tenantId) {
      LocalStorage.set('tenant.id', tenantId)
      Object.keys($settings('messages', {})).forEach(lang => {
        const newMsg = merge({}, originalMsgs[lang], $settings(`messages[${lang}]`))
        i18n.global.setLocaleMessage(lang, newMsg)
      })
    } else {
      LocalStorage.remove('tenant.id')
      Object.keys(originalMsgs).forEach(lang => {
        i18n.global.setLocaleMessage(lang, originalMsgs[lang])
      })
    }
    changeTheme($settings('theme'))
    if (!noReload) {
      try {
        // Forcibly navigate away from page to trigger "beforeRouteLeave" and wait for result
        await this.$router.push({ name: 'redirect', params: { to: { name: 'home' } } })
      } catch (err) {
        debug('Navigation was aborted.', tenantId, err)
      }
    }
  },
  async fetchRegionsData ({ commit }) {
    const { data } = await axios.get(`${import.meta.env.VITE_CDN_BASE_URL}/location/regions.json`)
    if (data) commit('setRegions', data)
  },
  async fetchStatesData ({ commit }) {
    const { data } = await axios.get(`${import.meta.env.VITE_CDN_BASE_URL}/location/states.json`)
    if (data) commit('setStates', data)
  },
  async fetchMetroData ({ commit }) {
    const { data } = await axios.get(`${import.meta.env.VITE_CDN_BASE_URL}/location/metro-areas-v2.json`)
    if (data) {
      // Remove duplicate area
      const filterData = data.filter((v, i, a) => a.findIndex(v2 => (v2.MetroArea === v.MetroArea)) === i)
      commit('setMetros', filterData)
    }
  },
  async fetchCitiesData ({ commit }) {
    const { data } = await axios.get(`${import.meta.env.VITE_CDN_BASE_URL}/location/cities-v2.json`)
    if (data) commit('setCities', data)
  },
  async loadUserSettings ({ commit }) {
    const promise = $api.get('/currentuser')
    const [hydrateResult] = await allSettled([promise.then(({ data }) => data)])
    if (hydrateResult.status !== 'fulfilled') {
      console.error('Error occurred while fetching user settings.', hydrateResult.reason, hydrateResult)
    } else {
      const userDarkMode = hydrateResult.value?.settings?.darkMode ?? null
      commit('setDarkMode', userDarkMode)
    }
    return hydrateResult.value.settings
  },
  async updateUserDarkMode ({ commit, getters }, newDarkModeSetting) {
    const updatedSettings = { ...getters.appSettings, darkMode: newDarkModeSetting }
    commit('setDarkMode', newDarkModeSetting)
    LocalStorage.set('darkMode', newDarkModeSetting)
    const promise = $api.put('/currentuser', { settings: updatedSettings })
    const [hydrateResult] = await allSettled([promise.then(({ data }) => data)])
    if (hydrateResult.status !== 'fulfilled') {
      console.error('Error occurred while updating user settings.', hydrateResult.reason, hydrateResult)
    }
  },
  async updateRoute ({ commit }, { to, from }) {
    commit('updateRoute', { to, from })
  },
  async setDarkModeFromLocalStorage ({ commit }) {
    if (LocalStorage.has('darkMode')) {
      const darkMode = LocalStorage.getItem('darkMode')
      commit('setDarkMode', darkMode)
    }
  }
}
