import { useFetchContent } from 'src/components/Content/timeline-editor/use-fetch-content'
import { unref, computed, getCurrentInstance } from 'vue'

import { cloneAndSetProperty, createUploadPayload } from 'src/utils/resource'
import cloneDeep from 'lodash/cloneDeep'
import { Notify } from 'quasar'
import { i18n } from 'src/boot/i18n'
import { $api } from 'src/boot/axios'

export async function removeTagsFn ({ resourceType, id }) {
  return $api.delete(`/${resourceType}/${unref(id)}/tags/all`)
}
export async function storeTagsFn ({ tagsData, resourceType, id }) {
  const tags = tagsData.filter(item => item?.tagLink?.source !== 'system')
  const tagsObj = {
    tags
  }
  if (tags.length > 0) return $api.put(`/${resourceType}/${unref(id)}/tags`, tagsObj)
}

export default function useExperienceDataEditor ({ content, resourceType, id, onFetch }) {
  const instance = getCurrentInstance()
  const { $route } = instance.proxy
  const isEditTimelineDisabled = computed(() => unref(originalContent).type === 'video' && !originalContent.value.media)
  const { savedContent: originalContent } = useFetchContent(id, null, resourceType, content)
  function setContentProperty (value, path, unsetOnEmpty) {
    content.value = cloneAndSetProperty(unref(content), path, value, unsetOnEmpty)
  }
  function setData (data) {
    content.value = data
    originalContent.value = cloneDeep(data)
  }

  function fetchData () {
    return $api(`/${resourceType}/${unref(id)}?_publicationState=preview`)
      .then(({ data }) => {
        setData(data)
        if (typeof onFetch === 'function') { onFetch(data) }
      })
      .catch(e => {
        console.error('Error occurred while fetching data.', e)
        content.value = null
      })
  }
  fetchData()

  async function onSubmit () {
    const tagsData = content.value.tags?.map?.(entry => {
      return {
        ...entry,
        tag: entry.id,
        resource: $route.params.id
      }
    }) || []
    const payload = createUploadPayload(unref(content))
    try {
      await $api.put(`/${resourceType}/${unref(id)}`, payload)
      if (unref(content)?.tags) await removeTags()
      if (tagsData.length > 0) await storeTags(tagsData)
      await fetchData()
      Notify.create({
        type: 'positive',
        message: i18n.t('notifications.saveSuccessful')
      })
    } catch (err) {
      console.error('Error occurred while saving.', err, err.response)
      Notify.create({
        type: 'negative',
        message: i18n.t('notifications.error')
      })
    }
  }

  async function removeTags () {
    return await removeTagsFn({ resourceType, id })
  }
  async function storeTags (tagsData) {
    return await storeTagsFn({ tagsData, resourceType, id })
  }

  return {
    originalContent,
    onSubmit,
    setData,
    isEditTimelineDisabled,
    setContentProperty,
    storeTags
  }
}
