import { QBtnDropdown } from 'quasar'
import { useBtnSize } from './utils/btn-size'
import { h } from 'vue'

export default (props, { attrs, slots }) => {
  const btnSize = useBtnSize(props)
  return h(QBtnDropdown, {
    ...props,
    ...attrs,
    noCaps: props.noCaps ?? true,
    size: btnSize.value
  }, slots)
}
