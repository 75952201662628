import { get } from 'lodash'

export default {
  apiUrl: (state, getters, rootState) => rootState.system.api.base,
  apiUrl2: (state, getters, rootState) => rootState.system.api.secondApi,
  user: (state, getters, rootState) => rootState.user,
  getUserFeature: (state, getters, rootState) => (path, defaultValue) => {
    return get((rootState.user && rootState.user.features) || {}, path, defaultValue)
  },
  appSettings: (state, getters, rootState) => {
    return state.settings
  },
  getAppSettings: (state, getters, rootState) => (path, defaultValue) => {
    return get(getters.appSettings, path, defaultValue)
  },
  currentTenant: (state, getters) => {
    if (!state.currentTenantId) return null
    return getters.user?.organizations.find(i => i.id === state.currentTenantId)
  },
  getCities: (state) => state.targeting.cities,
  getTimeZone: (state) => state.zone,
  getRegions: (state) => state.targeting.regions,
  getStates: (state) => state.targeting.states,
  getStatesAbbr: (state) => Object.values(state.targeting.states).sort(),
  getMetros: (state) => state.targeting.metros,
  getDarkMode: (state) => state.darkMode,
  getRoutes: (state) => state.routes
}
