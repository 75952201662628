import mergeWith from 'lodash/mergeWith'
import isArray from 'lodash/isArray'

const merge = (...args) => {
  return mergeWith(...args, (a, b) =>
    isArray(b) ? b : undefined
  )
}

export default merge
