const baseAPI = 'https://api.sourcesync.io/'
export default {
  system: {
    loaded: false,
    api: {
      user: `${baseAPI}users`,
      experience: `${baseAPI}experience/data`,
      activation: `${baseAPI}activations`
    }
  },
  app: {
    settings: {}
  },
  user: {}
}
