import { QPage, Screen } from 'quasar'
import { h } from 'vue'

export default (props, { attrs, slots }) => {
  return h(QPage, {
    ...props,
    ...attrs,
    class: {
      'q-pr-md': Screen.gt.xs,
      'q-pr-sm': Screen.lt.sm
    }
  }, slots)
}
