export default ({ app }) => {
  app.config.globalProperties.$rules = {
    required: val => !!val || '* Required',
    isValidJSON (val) {
      if (val === null || val === '') return true
      try {
        const obj = JSON.parse(val)
        return obj && typeof obj === 'object'
      } catch (e) { }
      return false
    }
  }
}
