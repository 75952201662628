<template>
  <div v-if="!isNaN(loadingProgress)">
    <div
      v-if="multiple"
      class="q-pa-sm"
    >
      {{ totalFiles }} {{ totalFiles > 1 ? $t('components.uploadProgressViewer.filesLoading') : $t('components.uploadProgressViewer.fileLoading') }}
    </div>
    <q-linear-progress
      size="36px"
      :model-value="loadingProgress"
      :buffer="1"
      :label="progressLabel"
      color="primary"
      class="q-mt-sm"
    >
      <div class="absolute-full flex flex-center">
        <q-badge
          color="white"
          class="q-pa-sm"
          text-color="primary"
          :label="progressLabel"
        />
      </div>
    </q-linear-progress>
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
  name: 'UploadProgressViewer',
  props: {
    loading: Object,
    totalFiles: Number,
    multiple: { type: Boolean, default: false }
  },
  setup (props) {
    const loadingProgress = computed(() => props.loading?.loaded / props.loading?.total)
    const progressLabel = computed(() => ((loadingProgress.value || 0) * 100).toFixed(0) + '%')
    return {
      loadingProgress,
      progressLabel
    }
  }

}
</script>
