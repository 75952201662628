import { ref, unref, getCurrentInstance, watch } from 'vue'
import { cloneDeep } from 'lodash'
import throttle from 'lodash/throttle'

import { useMonitorResourceChange } from './use-monoitor-resource-change'

export function useFetchContent (id, onContentChange, resourceType, contentRef = ref(null)) {
  const instance = getCurrentInstance()
  const { $api } = instance.proxy

  const savedContent = ref(null)
  const idRef = ref(id)

  let cancelContentWatcher

  watch(idRef, async () => {
    try {
      contentRef.value = null
      savedContent.value = null
      const { data: content } = await $api(`/${resourceType}/${unref(id)}?_publicationState=preview`)
      // FIXME - This file was originally designed for prod/dist but now it's being used for other resources as well.
      // However, this `data` is only available for prod/dist. So, we need to check if the data is available or not.
      // Implement better solution for this.
      if (['productions', 'distributions'].includes(resourceType) && content.data && Array.isArray(content.data)) {
        content.data = {
          timeline: {
            activations: {
              items: content.data
            },
            tags: {
              items: []
            }
          }
        }
      }
      content.data = Object.assign({
        timeline: {
          activations: { items: [] },
          tags: { items: [] }
        }
      }, content.data)
      content.settings = content.settings || {}
      contentRef.value = content
      savedContent.value = cloneDeep(content)
      if (onContentChange && !cancelContentWatcher) cancelContentWatcher = watch(contentRef, throttle(onContentChange, 200), { deep: true })
    } catch (e) {
      console.error('Error occurred while fetching data.', e)
    }
  }, {
    immediate: true
  })

  if (import.meta.env.DEV) {
    useMonitorResourceChange(contentRef, id, resourceType)
  }

  return {
    content: contentRef,
    savedContent
  }
}
