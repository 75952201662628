import uuid from 'src/utils/uuid'
import merge from 'src/utils/merge'
import { clone } from 'lodash'

const createBlock = (settings = {}, overrides = {}) => {
  if (!overrides.id) {
    overrides.id = uuid()
  }
  return merge(clone(settings), overrides)
}

export default createBlock
