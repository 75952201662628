import handleApiError from 'src/utils/handle-api-error'
import { reactive, unref } from 'vue'
import { $api } from 'src/boot/axios'
import { i18n } from 'src/boot/i18n'
import axios from 'axios'
import { $notify } from 'src/boot/notify'

/**
 * Updates asset with incoming file
 * @param {{ storedFile: file, storedUrl: url, asset: object }} opts
 */
export function useUpdateFile () {
  const loading = reactive({
    loaded: 0,
    total: 0
  })
  async function updateAsset ({ storedFile, storedUrl, asset }) { // get incoming img/url and updates asset
    let file
    if (unref(storedFile)) file = unref(storedFile)
    if (unref(storedUrl)) {
      const { data } = await handleApiError(axios.get(`${unref(storedUrl)}`, { responseType: 'blob' }))
      file = data
    }
    if (file) {
      const blob = new Blob([file], { type: file.mime || file.type })
      const formData = new FormData()
      formData.append('files.file', blob)
      formData.append('data', JSON.stringify({ ...asset }))
      await handleApiError($api.put(`/user-medias/${asset.id}`, formData, {
        onUploadProgress: function ({ loaded, total }) {
          loading.loaded = loaded
          loading.total = total
        }
      }))
      $notify.saveSuccess()
    }
  }
  async function uploadAssets ({ storedFile, storedUrl, currentFolderId }) { // get incoming img/url and updates asset
    let file
    const uploaded = []
    if (unref(storedFile)) file = Array.isArray(unref(storedFile)) ? unref(storedFile) : [unref(storedFile)]
    if (unref(storedUrl)) {
      const { data } = await handleApiError(axios.get(`${unref(storedUrl)}`, { responseType: 'blob' }))
      file = [data]
    }
    if (file) {
      for (let i = 0; i < file.length; i++) {
        const blob = new Blob([file[i]], { type: file[i].mime || file[i].type })
        const formData = new FormData()
        formData.append('files.file', blob, `${file[i].name}.${(file[i].mime || file[i].type).replace('image/', '')}`)
        formData.append('data', JSON.stringify({ parent: currentFolderId, title: file[i].name }))
        const { data } = await handleApiError($api.post('/user-medias/', formData, {
          onUploadProgress: function ({ loaded, total }) {
            loading.loaded = loaded
            loading.total = total
          }
        }))
        $notify.positive(i18n.t('notifications.savedAssets'))
        uploaded.push(data)
      }
    }
    if (uploaded.length > 0) return uploaded
  }
  return {
    updateAsset,
    uploadAssets,
    loading
  }
}
