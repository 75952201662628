<template>
  <q-chip
    v-if="tag.source === 'system' && tag.tagLink.score"
    :removable="tag.tagLink.source !== 'system'"
    :style="`background-color: ${chipColor}; color: ${textColor};`"
    @remove="$emit('remove')"
  >
    <q-avatar
      text-color="white"
      :style="`background-color: ${textColor};`"
    >
      {{ tag.tagLink.score }}
    </q-avatar>
    {{ tag.value }}
  </q-chip>
  <q-chip
    v-else
    :label="tag.value"
    :removable="tag.tagLink.source !== 'system'"
    @remove="$emit('remove')"
  />
</template>

<script>
import { computed } from 'vue'
export default {
  props: {
    tag: {
      type: Object,
      required: true
    }
  },
  emits: ['remove'],
  setup (props) {
    const getColorByScore = (tag) => {
      if (tag.source !== 'system' || !tag.tagLink.score) return null
      else {
        if (tag.tagLink.score >= 0 && tag.tagLink.score <= 59) return 'rgb(183,28,28)'
        else if (tag.tagLink.score >= 60 && tag.tagLink.score <= 79) return 'rgb(255,141,78)'
        else if (tag.tagLink.score >= 80 && tag.tagLink.score <= 89) return 'rgb(211,161,32)'
        else return 'rgb(63,185,60)'
      }
    }
    const color = computed(() => getColorByScore(props.tag))
    const chipColor = computed(() => `${color.value}`.replace('rgb', 'rgba').replace(')', ', 0.5)'))
    const textColor = computed(() => `${color.value}`)
    return {
      getColorByScore,
      chipColor,
      textColor
    }
  }
}
</script>
