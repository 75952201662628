export function isFirebaseLoggedIn (state) {
  return !!state.firebase.loggedIn
}
export function isLoggedIn (state, getters, rootState) {
  return !!rootState.user?.role
}
export const authHeaders = (state, getters, rootState, rootGetters) => {
  return {
    Authorization: `Bearer ${rootState.user.jwt}`,
    ...(rootGetters['adminapp/currentTenant'] ? { 'source-tenant': rootGetters['adminapp/currentTenant'].id } : {})
  }
}
