import { QTab } from 'quasar'
import { useBtnSize } from './utils/btn-size'
import { h } from 'vue'

export default (props, { attrs, slots }) => {
  // Adjusting attributes as necessary
  attrs.size = useBtnSize(attrs).value

  // Creating and returning the QBtn component with adjusted data and slots
  return h(QTab, {
    ...props,
    ...attrs,
    noCaps: props.noCaps ?? true
  }, slots)
}
